import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

const Layout = styled.main`
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FourOhFour = ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>404 - Page Not Found</title>
      <link rel="canonical" href="https://erinolmon.net" />
    </Helmet>
    <div>
      <h1>This Page is Not Found.</h1>
      <p>
        Something went wrong. Apologies. If something should have been here,
        consider{" "}
        <a href="https://github.com/erinoerin/erinolmon.net/issues/new">
          creating a github issue
        </a>
        .
      </p>
      <p>
        <Link to="/">Return to the Home Page.</Link>
      </p>
    </div>
  </Layout>
);

export default FourOhFour;